import React from "react";
import { FormattedMessage } from "react-intl";
import { createAndRedirectToNewHub } from "../../../utils/phoenix-utils";
import { Button } from "../../../react-components/input/Button";
import styles from "../../../react-components/input/Button.scss";
import customStyles from "./CreateTutorialRoomDropdown.scss";
import { useCssBreakpoints } from "react-use-css-breakpoints";
import { ReactComponent as CaretDownIcon } from "../../../react-components/icons/CaretDown.svg";

export function CreateTutorialRoomDropdown() {
  const breakpoint = useCssBreakpoints();
  let firstTimeClickingButton = true;

  function toggleTutorialOptions() {
    const optionsStyle = document.getElementById("options-tutorial").style;
    if (optionsStyle.display === "none" || optionsStyle.display === "") {
      optionsStyle.display = "flex";
    } else {
      optionsStyle.display = "none";
    }

    if (firstTimeClickingButton == true) {
      // If this is the first time clicking the button, re-run the toggle once, since it can't find the options element yet the first time.
      firstTimeClickingButton = false;
      const meetingRoomButton = document.getElementById("options-tutorial").children[1]; // Reset the padding of the Meeting Room button, because this one is unnecessarily wide.
      meetingRoomButton.style.paddingLeft = 0;
      meetingRoomButton.style.paddingRight = 0;
    }

    if (document.getElementById("options")) {
      document.getElementById("options").style.display = "none"; // Close the other dropdown if it's there in case it is open.
    }
  }

  //ACC scenes
  let scenes = [
    { id: 1, name: "Training Desktop", scene_id: "osxfzra" },
    { id: 2, name: "Training VR", scene_id: "mKv8bqE" },
    { id: 3, name: "Training Mobile", scene_id: "65tpyoy" }
  ];
  //PROD SCENES
  if (window.location.href.includes("meta-college.uk")) {
    scenes = [
      { id: 1, name: "Training Desktop", scene_id: "mdAqK5t" },
      { id: 2, name: "Training VR", scene_id: "jeZSoAo" },
      { id: 3, name: "Training Mobile", scene_id: "X9N4QZH" }
    ];
  }

  return (
    <div className={customStyles.buttonContainer}>
      <Button
        className={styles.dropdownButton}
        thick={breakpoint === "sm" || breakpoint === "md"}
        xl={breakpoint !== "sm" && breakpoint !== "md"}
        preset="landing"
        onClick={e => {
          e.preventDefault();
          // createAndRedirectToNewHub(null, null, false);
          toggleTutorialOptions();
        }}
      >
        <FormattedMessage id="create-tutorial-room-button" defaultMessage="Interactive Tutorial" />
        <CaretDownIcon />
      </Button>

      {/* Create an option / dropdown element for each scene in the list. */}
      <div id="options-tutorial" className={styles.options + " " + customStyles.optionsContainer}>
        {scenes.map(scene => {
          return (
            <Button
              key={scene.id}
              className={styles.optionsButtons}
              thick
              xl={breakpoint !== "sm" && breakpoint !== "md"}
              preset="signin"
              onClick={e => {
                e.preventDefault();
                createAndRedirectToNewHub(scene.name, scene.scene_id, false);
              }}
            >
              {scene.name}
            </Button>
          );
        })}
      </div>
    </div>
  );
}
