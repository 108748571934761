/// BXR
/// MetaCollege
const adjectives = ["123", "456", "789", "987", "654", "321", "234", "567", "890", "108"];

export function generateHubName() {
  const randItem = set => {
    const s = set[Math.floor(Math.random() * set.length)];
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return `${randItem(adjectives)}${randItem(adjectives)}${randItem(adjectives)}`;
}
