import React from "react";
import { FormattedMessage } from "react-intl";
import { createAndRedirectToNewHub } from "../../../utils/phoenix-utils";
import { Button } from "../../../react-components/input/Button";
import styles from "../../../react-components/input/Button.scss";
import customStyles from "./CreateRoomDropdown.scss";
import { useCssBreakpoints } from "react-use-css-breakpoints";
import { ReactComponent as CaretDownIcon } from "../../../react-components/icons/CaretDown.svg";

export function CreateHomepageDropdown() {
  const breakpoint = useCssBreakpoints();
  let firstTimeClickingButton = true;

  function toggleOptions() {
    const optionsStyle = document.getElementById("options").style;
    if (optionsStyle.display === "none" || optionsStyle.display === "") {
      optionsStyle.display = "flex";
    } else {
      optionsStyle.display = "none";
    }

    if (firstTimeClickingButton == true) {
      // If this is the first time clicking the button, re-run the toggle once, since it can't find the options element yet the first time.
      firstTimeClickingButton = false;
      const meetingRoomButton = document.getElementById("options").children[1]; // Reset the padding of the Meeting Room button, because this one is unnecessarily wide.
      meetingRoomButton.style.paddingLeft = 0;
      meetingRoomButton.style.paddingRight = 0;
    }

    if (document.getElementById("options-tutorial")) {
      document.getElementById("options-tutorial").style.display = "none"; // Close the other dropdown if it's there in case it is open.
    }
  }

  //ACC scenes
  let scenes = [
    { id: 0, name: "Street", scene_id: "QPh92Z9" },
    { id: 1, name: "Immersive room", scene_id: "6mALGv6" },
    { id: 2, name: "Barn", scene_id: "Tz6hkeJ" },
    { id: 3, name: "e-Sports arena", scene_id: "CEh7cNW" },
    { id: 4, name: "Agile learning", scene_id: "873ahdB" },
    { id: 5, name: "Workshop", scene_id: "wEZpv9t" },
    { id: 6, name: "Gallery", scene_id: "7HfUxM7" },
    { id: 7, name: "Learning cafe", scene_id: "uRuUU29" },
    { id: 8, name: "Auditorium", scene_id: "c6JbVf4" }
  ];
  //PROD SCENES
  if (window.location.href.includes("meta-college.uk")) {
    scenes = [
      { id: 0, name: "Street", scene_id: "TQ3qukq" },
      { id: 1, name: "Immersive room", scene_id: "3xzZjJe" },
      { id: 2, name: "Barn", scene_id: "S9NDLD6" },
      { id: 3, name: "e-Sports arena", scene_id: "zBXg94i" },
      { id: 4, name: "Agile learning", scene_id: "zPgHWDH" },
      { id: 5, name: "Workshop", scene_id: "JhFxHkd" },
      { id: 6, name: "Gallery", scene_id: "6fJrjJn" },
      { id: 7, name: "Learning cafe", scene_id: "GwuiLbY" },
      { id: 8, name: "Auditorium", scene_id: "DxqhYhz" }
    ];
  }

  return (
    <div className={customStyles.buttonContainer}>
      <Button
        className={styles.dropdownButton}
        thick={breakpoint === "sm" || breakpoint === "md"}
        xl={breakpoint !== "sm" && breakpoint !== "md"}
        preset="landing"
        onClick={e => {
          e.preventDefault();
          // createAndRedirectToNewHub(null, null, false);
          toggleOptions();
        }}
      >
        <FormattedMessage id="create-room-button" defaultMessage="Create Invite-Only Room" />
        <CaretDownIcon />
      </Button>

      {/* Create an option / dropdown element for each scene in the list. */}
      <div id="options" className={styles.options + " " + customStyles.optionsContainer}>
        {scenes.map(scene => {
          return (
            <Button
              key={scene.id}
              className={styles.optionsButtons}
              thick
              xl={breakpoint !== "sm" && breakpoint !== "md"}
              preset="signin"
              onClick={e => {
                e.preventDefault();
                createAndRedirectToNewHub(scene.name, scene.scene_id, false);
              }}
            >
              {scene.name}
            </Button>
          );
        })}
      </div>
    </div>
  );
}
